import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment";

import useAsync from "hooks/useAsync";
import {
  ApiError,
  PagedListOfInvoicesOfCompany,
} from "services/borbalo-main.service";
import { bussinesInvoicesService } from "services/bussinesInvoicesService";
import MyTable from "components/MyTable";

import MarkInvoiceAsPaid from "./MarkInvoiceAsPaid";
import InvoiceSentIcon from "./InvoiceSentIcon";
import DownloadInvoice from "./DownloadInvoice";

const ProvidersInvoices = ({ title }: { title: string }) => {
  const { id } = useParams();
  const { data, isLoading, run } = useAsync<
    PagedListOfInvoicesOfCompany,
    ApiError
  >(false);

  const getBussinesInvoices = () => {
    if (id) {
      run(bussinesInvoicesService.groupedByCompany(undefined, id));
    }
  };

  useEffect(() => {
    getBussinesInvoices();
  }, [id]);

  const bussines = data?.entities?.[0];

  const dataInfo =
    bussines?.invoices
      ?.filter(item => item?.monthSummary)
      ?.map(item => [
        moment(item.month).format("DD.MM.YYYY"),
        `${item.monthSummary}${
          bussines?.company?.additionalCorrectionInNextMonthInvoice !==
            undefined &&
          bussines?.company?.additionalCorrectionInNextMonthInvoice !== 0
            ? ` (${bussines?.company?.additionalCorrectionInNextMonthInvoice}%)`
            : ""
        }`,
        <MarkInvoiceAsPaid invoice={item} companyId={id} />,
        <InvoiceSentIcon sent={item?.sent} />,
        <DownloadInvoice month={item?.month} companyId={id} />,
      ]) ?? [];

  const tableData = [
    ["Month", "Month Summary", "Paid", "Sent", ""],
    ...dataInfo,
  ];

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3" mb={1.6}>
          {`${title} ${bussines?.company.companyName || ""}`}
          {isLoading && (
            <CircularProgress
              sx={{
                alignSelf: "center",
                marginLeft: 3.6,
              }}
            />
          )}
        </Typography>
      </Box>
      <Typography variant="h4" mb={1.6}>
        Total {bussines?.invoices?.length}
      </Typography>
      <div className="column"></div>
      <MyTable tableData={tableData} />
    </>
  );
};

export default ProvidersInvoices;
