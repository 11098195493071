import { useEffect, useRef } from "react";
import { saveAs } from "file-saver";
import moment from "moment";

import useAsync from "hooks/useAsync";
import { bussinesInvoicesService } from "services/bussinesInvoicesService";
import { ApiError, FileResponse } from "services/borbalo-main.service";

export const useLoadInvoice = () => {
  const ref = useRef<Date>();

  const async = useAsync<FileResponse, ApiError>();

  const loadInvoice = (id: string, date: Date) => {
    ref.current = date;
    async.run(
      bussinesInvoicesService.getCompanyInvoice(
        id,
        date.getFullYear(),
        date.getMonth() + 1,
      ),
    );
  };

  useEffect(() => {
    if (async.isSuccess && async.data && ref.current) {
      saveAs(
        async.data.data,
        async.data.fileName ??
          `Borbalo-Invoice-${moment(ref.current).format("MM-YYYY")}.pdf`,
      );
    }
  }, [async.isSuccess]);

  return {
    isInvoiceLoading: async.isLoading,
    loadInvoice,
  };
};
