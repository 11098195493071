import React, { useRef, useState } from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import clsx from "clsx";
import { usePreloadedImage } from "./usePreloadedImage";
import { IconChevronDown } from "icons/IconChevronDown";
import { IconChevronRight } from "icons/IconChevronRight";
import { IconDiscountBannerGradient } from "icons/IconDiscountBannerGradient";
import { IconFacebook } from "icons/IconFacebook";
import { IconInstagram } from "icons/IconInstagram";
import { IconMail } from "icons/IconMail";
import { IconPhone } from "icons/IconPhone";
import { IconTiktok } from "icons/IconTiktok";
import { IconWeb } from "icons/IconWeb";
import styles from "./CompanyPreview.module.scss";
import {
  DiscountProgramBrand,
  DiscountProgramBrandLocation,
} from "services/borbalo-main.service";
import { PreviewLanguage } from "store/locations/slice";

// TODO: FIX
const alfa = "abcdefghijklmnopqrstuvwxyz";

export const CompanyPreview = ({
  brandToShow,
  locations,
  language,
}: {
  brandToShow?: DiscountProgramBrand;
  locations: DiscountProgramBrandLocation[];
  language: PreviewLanguage;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isDiscountShow, setIsDiscountShow] = useState(false);
  const isEn = language === "en";
  const imageSrc = `/assets/company/preview/${language}`;

  const { imageUrl, isLoading: isLogoLoading } = usePreloadedImage(
    brandToShow?.shortLogoUrl,
  );

  const texts = {
    description: isEn
      ? "The discount code will magically appear when you approach one of the brand’s branches"
      : "ფასდაკლების კოდი ჯადოსნურად გამოჩნდება, როდესაც ბრენდის ერთ-ერთ ფილიალს მიუახლოვდებით",
    buttonText: isEn ? "Code for Online Shopping" : "კოდი ონლაინ შოპინგისთვის",
    mapSection: isEn ? "Branches" : "ფილიალები",
    categories: isEn ? "Discount categories" : "ფასდაკლების კატეგორიები",
    rules: isEn ? "Discount rules" : "ფასდაკლების წესები",
    open: isEn ? "Now is open" : "ახლა ღიაა",
    directions: isEn ? "Directions" : "მიმართულება",
    onlineStore: isEn ? "Online Store" : "ონლაინ მაღაზია",
    onlineStoreDescription: isEn
      ? "To receive the discount use a promo code at checkout"
      : "ფასდაკლების მისაღებად გამოიყენეთ პრომო კოდი გადახდისას",
  };

  const title = isEn ? brandToShow?.enTitle : brandToShow?.geTitle;
  const subCategory = isEn
    ? brandToShow?.subCategory?.name
    : brandToShow?.subCategory?.name;

  const locationsToShow = locations.filter(
    location =>
      location.brandId === brandToShow?.id ||
      location.brandId === brandToShow?.originalBrandId,
  );

  const socials = [
    {
      icon: IconPhone,
      title: isEn ? "Phone" : "დარეკვა",
      isActive: `tel:${brandToShow?.phone}`,
    },
    {
      icon: IconMail,
      title: isEn ? "Email" : "წერილი",
      isActive: `mailto:${brandToShow?.email}`,
    },
    {
      icon: IconWeb,
      title: isEn ? "Website" : "ვებგვერდი",
      isActive: brandToShow?.websiteLink,
    },
  ];

  const socials2 = [
    {
      icon: IconInstagram,
      title: isEn ? "Instagram" : "ინსტაგრამი",
      isActive: brandToShow?.instagramLink,
    },
    {
      icon: IconFacebook,
      title: isEn ? "Facebook" : "ფეისბუქი",
      isActive: brandToShow?.facebookLink,
    },
    {
      icon: IconTiktok,
      title: isEn ? "TikTok" : "ტიკტოკი",
      isActive: brandToShow?.tikTokLink,
    },
  ];

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["header"]}>
        <img
          src={"/assets/company/preview/arrow-left.svg"}
          width={20}
          height={16}
          alt={"arrow-left"}
        />
      </div>
      <div className={styles["content"]} ref={contentRef}>
        <div className="mx16">
          <div className="row-space-between mb8">
            <div className="column-space-around overflow-hidden">
              <div className="medium24-text color-white break-word mb4">
                {title}
              </div>
              <div className="medium16-text color-whiteGrey break-word">
                {subCategory}
              </div>
            </div>
            <div className="column">
              {!isLogoLoading && (
                <img
                  src={imageUrl}
                  className={styles["short-logo"]}
                  width={64}
                  height={64}
                  alt={"square-logo"}
                />
              )}
            </div>
          </div>
          <div className="regular16-text color-white break-word mb16">
            {isEn ? brandToShow?.enDescription : brandToShow?.geDescription}
          </div>
          <img
            src={`${imageSrc}/switcher.svg`}
            width={343}
            height={39}
            className="mb32 pointer"
            alt={"switcher"}
          />
          <div className={clsx("mb8", styles["banner-wrapper"])}>
            <IconDiscountBannerGradient
              fill={brandToShow?.brandColor || "#00c6be"}
            />

            <img
              src={"/assets/company/preview/magic-card.svg"}
              width={72.5}
              height={44.5}
              className={clsx("mb8", styles["banner-wrapper-default-img"])}
              alt={"default-banner"}
            />
            {/* {brandToShow?.mainDiscountAmount && (
              <div
                className={clsx(styles["discount"], "bold32-text color-black")}
              >
                -{profile?.mainDiscountAmount}%
              </div>
            )} */}
          </div>
          <div className="regular12-text color-whiteGrey mb16 mx16">
            {texts.description}
          </div>
          {brandToShow?.onlineStoreCode && (
            <div
              className={clsx(
                "mb8 row-space-between row-align-center px16 mb16",
                styles["banner-button"],
              )}
            >
              <div
                className={styles["banner-button-gradient"]}
                style={{
                  background: `radial-gradient(
              ellipse farthest-side at 0 0,
              ${brandToShow?.brandColor || "#00c6be"} 0%,
              #fff
            )`,
                }}
              />
              <div className="medium16-text zIndex2">{texts.buttonText}</div>
              <IconChevronRight className="color-black" size={20} />
            </div>
          )}
        </div>
        <div className={styles["separator"]} />
        <div
          className="unstyled-button w-auto row-space-between row-align-center px16 py16"
          onClick={() => setIsDiscountShow(s => !s)}
        >
          <div className="regular16-text color-white pointer">
            {texts.categories}
          </div>
          <IconChevronDown
            className={clsx({ flip: isDiscountShow }, "color-main")}
            size={19}
          />
        </div>
        {isDiscountShow && (
          <div className="regular12-text color-white mx16 mb16">
            • {brandToShow?.category}
          </div>
        )}
        <div className={styles["separator"]} />
        <div className="unstyled-button w-auto row-space-between row-align-center px16 py16">
          <div className="regular16-text color-white pointer">
            {texts.rules}
          </div>
          <IconChevronRight className="color-main" size={19} />
        </div>
        <div className={styles["separator"]} />
        <div className="row-space-between py16 px16">
          {socials.map(social => (
            <a
              key={social.title}
              className={clsx(styles["button-with-icon"], "pointer")}
              href={social.isActive ?? "#"}
              target="_blank"
              rel="noreferrer"
            >
              <social.icon
                size={24}
                className={social.isActive ? "color-main" : "color-whiteGrey"}
              />
              <div className="regular12-text color-white">{social.title}</div>
            </a>
          ))}
        </div>
        <div className={clsx(styles["separator"], "mb16")} />

        {brandToShow?.onlineStoreLink && (
          <div className="column gap8 mx16 br14 mb8">
            <div className="medium18-text mx16 color-white">
              {texts.onlineStore}
            </div>
            <div className="row-align-center gap16 p16 bg-greyMobile flex1 br14">
              <img
                className="br10"
                src={imageUrl}
                width={64}
                height={64}
                alt="online-store"
              />
              <div className="column flex1">
                <div className="medium16-text color-white mb8">
                  {title}`s {subCategory}
                </div>
                <div className="regular12-text color-whiteGrey">
                  {brandToShow?.onlineStoreLink}
                </div>
              </div>
              <IconChevronRight
                className={clsx("color-whiteGrey", styles["arrow-icon"])}
                size={24}
              />
            </div>
            <div className="regular12-text color-whiteGrey mx16"></div>
          </div>
        )}

        <div className="medium18-text color-white mx32 mb16">{"ფილიალები"}</div>
        {!!locationsToShow.length && (
          <APIProvider apiKey="AIzaSyAVr8gRuZGWGq18bv6Tx1TGPCA6uVhrLoc">
            <div className={styles["map-wrapper"]}>
              <Map
                mapId={"preview-map"}
                className={styles["map"]}
                zoom={6}
                zoomControl={false}
                center={{
                  lat: 41.716667,
                  lng: 44.783333,
                }}
              >
                {locationsToShow.map(
                  ({ id, coordinates, locationNameEn, locationNameGe }) => (
                    <Marker
                      key={id}
                      position={{
                        lat: coordinates?.latitude ?? 0,
                        lng: coordinates?.longitude ?? 0,
                      }}
                      title={isEn ? locationNameEn : locationNameGe}
                    />
                  ),
                )}
              </Map>
            </div>
          </APIProvider>
        )}
        {locationsToShow?.map((location, index) => (
          <React.Fragment key={location.id}>
            {index > 0 && <div className={clsx(styles["separator"], "mb16")} />}
            <div className="row mx16 gap16 mb16">
              <div className="column overflow-hidden flex1">
                <div className="row gap8 overflow-hidden mb4">
                  <div className="regular16-text color-main text-uppercase">
                    {alfa[index]}
                  </div>
                  <div className="regular16-text color-white flex1">
                    {isEn ? location.locationNameEn : location.locationNameGe}
                  </div>
                </div>
                <div className="regular14-text color-whiteGrey ml16 mb4">
                  {location.address}
                </div>

                <div className="regular14-text color-main ml16">
                  {texts.open}
                </div>
              </div>
              <div className="column-justify-center">
                <img
                  src={`${imageSrc}/direction-button.svg`}
                  width={71}
                  height={61}
                  alt="Direction"
                />
              </div>
            </div>
          </React.Fragment>
        ))}
        <div className={clsx(styles["separator"])} />
        <div className="row-space-between py16 px16 mb64">
          {socials2.map(social => (
            <a
              key={social.title}
              className={clsx(styles["button-with-icon"], "pointer")}
              href={social.isActive ?? "#"}
              target="_blank"
              rel="noreferrer"
            >
              <social.icon
                size={24}
                className={social.isActive ? "color-main" : "color-whiteGrey"}
              />
              <div className="regular12-text color-white">{social.title}</div>
            </a>
          ))}
        </div>
        <img
          src={`${imageSrc}/last-section.svg`}
          width={343}
          height={56}
          className="mb32 pointer mx16"
          alt={"last-button"}
        />
      </div>
    </div>
  );
};
