import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const GroupsTable = ({
  tableData,
  onPress,
  limitText,
  groups,
}: {
  tableData: (string | undefined | React.ReactNode)[][];
  onPress?: (index: number) => void;
  buttonText?: string;
  limitText?: boolean;
  isButtonDisabled?: boolean;
  groups?: Record<string, React.ReactNode[]>;
}) => {
  const classes = useStyles();

  const header = tableData[0];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="my table">
        <TableHead>
          <TableRow>
            {header.map((headerItem, index) => (
              <TableCell key={index}>{headerItem}</TableCell>
            ))}
            {!!onPress && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {groups && Object.keys(groups)?.length ? (
              <>
                {Object.keys(groups).map((group, index) => {
                  return (
                    <React.Fragment key={group + index}>
                      {groups[group]?.length > 0 && (
                        <TableRow
                          sx={{
                            backgroundColor: "rgba(0, 198, 190, 0.1)",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            colSpan={header?.length}
                            sx={{
                              fontSize: 20,
                              textAlign: "center",
                            }}
                          >
                            {group}
                          </TableCell>
                        </TableRow>
                      )}

                      {groups[group]?.map((row, index) => (
                        <TableRow key={index}>
                          {Array.isArray(row) &&
                            row?.map(
                              (
                                text: string | React.ReactNode,
                                rowIndex: number,
                              ) => (
                                <React.Fragment key={group + index + rowIndex}>
                                  {typeof text === "string" ? (
                                    <TableCell
                                      key={text + rowIndex + index}
                                      component="th"
                                      scope="row"
                                      sx={{
                                        width:
                                          limitText && rowIndex === 1
                                            ? "400px"
                                            : "auto",
                                      }}
                                    >
                                      {limitText ? (
                                        <div
                                          style={{
                                            maxHeight: 121,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 6,
                                            maxWidth: 300,
                                          }}
                                        >
                                          {text}
                                        </div>
                                      ) : (
                                        text
                                      )}
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      key={index + rowIndex}
                                      component="th"
                                      scope="row"
                                      sx={{
                                        width:
                                          limitText && rowIndex === 1
                                            ? "600px"
                                            : "auto",
                                      }}
                                    >
                                      {text}
                                    </TableCell>
                                  )}
                                </React.Fragment>
                              ),
                            )}
                        </TableRow>
                      ))}
                    </React.Fragment>
                  );
                })}
              </>
            ) : null}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GroupsTable;
