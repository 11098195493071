import { useState } from "react";
import { Switch } from "@mui/material";
import moment from "moment";

import useAsync from "hooks/useAsync";
import { ApiError, IInvoiceInfo } from "services/borbalo-main.service";
import { bussinesInvoicesService } from "services/bussinesInvoicesService";

const MarkInvoiceAsPaid = ({
  invoice,
  companyId,
}: {
  invoice: IInvoiceInfo;
  companyId: string | undefined;
}) => {
  const changeStateAsync = useAsync<void, ApiError>();
  const [changedInvoices, setChangedInvoices] = useState<String[]>([]);

  const handleChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IInvoiceInfo,
  ) => {
    const { checked } = event.target;

    if (checked && companyId) {
      changeStateAsync.run(
        bussinesInvoicesService.markInvoiceAsPaid(
          companyId,
          moment(item.month, "YYYY.MM.DD").year(),
          moment(item.month, "YYYY.MM.DD").month() + 1,
        ),
      );

      setChangedInvoices(prevState => [...prevState, item.number || ""]);
    }
  };

  return (
    <Switch
      defaultChecked={invoice?.paid}
      disabled={
        changeStateAsync.isLoading ||
        invoice?.paid ||
        changedInvoices.includes(invoice?.number)
      }
      onChange={e => handleChangeSwitch(e, invoice)}
    />
  );
};

export default MarkInvoiceAsPaid;
