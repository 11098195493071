import { useEffect, useMemo } from "react";
import { CircularProgress, Typography } from "@mui/material";
import moment from "moment";

import useAsync from "hooks/useAsync";
import {
  ApiError,
  PagedListOfInvoicesOfMonth,
} from "services/borbalo-main.service";
import { bussinesInvoicesService } from "services/bussinesInvoicesService";
import MarkInvoiceAsPaid from "pages/ProvidersInvoices/MarkInvoiceAsPaid";
import InvoiceSentIcon from "pages/ProvidersInvoices/InvoiceSentIcon";
import DownloadInvoice from "pages/ProvidersInvoices/DownloadInvoice";

import GroupsTable from "./GroupsTable";

const BussinesInvoicesByMonthProviders = ({ title }: { title: string }) => {
  const { data, isLoading, run } = useAsync<
    PagedListOfInvoicesOfMonth,
    ApiError
  >();

  useEffect(() => {
    run(bussinesInvoicesService.groupedByMonth());
  }, []);

  const tableData = [
    [
      "Company Name",
      "Company Email",
      "Number",
      "MonthSummary",
      "Paid",
      "Sent",
      "",
    ],
  ];

  const grouppedLocationsNames = useMemo(() => {
    if (data?.entities) {
      return data.entities.reduce(
        (acc, item) => {
          const monthKey = moment(item.month).format("MM.YYYY").toString();
          const invoices =
            item.companyInvoices
              ?.filter(invoice => invoice.invoice?.monthSummary)
              ?.map(invoice => [
                invoice.company.companyName,
                invoice.company.companyEmail,
                invoice.invoice.number,
                `${invoice.invoice.monthSummary}${
                  invoice.company.additionalCorrectionInNextMonthInvoice !== 0
                    ? ` (${invoice.company.additionalCorrectionInNextMonthInvoice}%)`
                    : ""
                }`,
                <MarkInvoiceAsPaid
                  invoice={invoice.invoice}
                  companyId={invoice.company.companyId}
                />,
                <InvoiceSentIcon sent={invoice.invoice.sent} />,
                <DownloadInvoice
                  month={invoice.invoice?.month}
                  companyId={invoice.company.companyId}
                />,
              ]) ?? [];

          return {
            ...acc,
            [monthKey]: invoices,
          };
        },
        {} as Record<string, React.ReactNode[]>,
      );
    }

    return {};
  }, [data?.entities]);

  return (
    <>
      <Typography variant="h3" mb={1.6}>
        {title}
        {isLoading && (
          <CircularProgress
            sx={{
              alignSelf: "center",
              marginLeft: 3.6,
            }}
          />
        )}
      </Typography>
      <Typography variant="h4" mb={1.6}>
        Total months {data?.total}
      </Typography>
      <GroupsTable tableData={tableData} groups={grouppedLocationsNames} />
    </>
  );
};

export default BussinesInvoicesByMonthProviders;
